import React from 'react';
import './index.css'; // Import CSS for styling

const SimpleIframe = () => {
  return (
    <h1>coming soon</h1>
  );
};


export default SimpleIframe;

